var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("KIconButton", {
    attrs: {
      icon: "tune",
      ariaLabel: _vm.$tr("toggleSettingsSideBar"),
      "data-test": "settings button",
      size: "small",
    },
    on: {
      click: function ($event) {
        return _vm.$emit("click")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }