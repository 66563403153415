var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bottom-bar",
      style: { backgroundColor: _vm.$themePalette.grey.v_200 },
    },
    [
      _c("div", { staticClass: "bottom-bar-heading" }, [
        _vm.heading
          ? _c("h3", [_vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")])
          : _vm._e(),
      ]),
      _c("transition", { attrs: { mode: "in-out" } }, [
        _vm.locationsAreReady
          ? _c("div", [
              _c("div", { staticClass: "d-t" }, [
                _c("div", { staticClass: "d-t-r" }, [
                  _c(
                    "div",
                    { staticClass: "bottom-bar-progress-container d-t-c" },
                    [
                      _c("div", { staticClass: "bottom-bar-progress" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$formatNumber(_vm.sliderValue / 100, {
                                style: "percent",
                              })
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]
                  ),
                  _c("div", { staticClass: "d-t-c full-width" }, [
                    _c("input", {
                      staticClass: "full-width",
                      attrs: {
                        type: "range",
                        min: 0,
                        max: 100,
                        step: _vm.sliderStep,
                        "aria-label": _vm.$tr("jumpToPositionInBook"),
                      },
                      domProps: { value: _vm.sliderValue },
                      on: {
                        change: function ($event) {
                          return _vm.handleChange($event.target.value)
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ])
          : _c(
              "div",
              { staticClass: "loader-container" },
              [
                _c("KLinearLoader", {
                  staticClass: "loader",
                  attrs: { type: "indeterminate", delay: false },
                }),
                _c("span", [_vm._v(_vm._s(_vm.$tr("preparingSlider")))]),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }