var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("KIconButton", {
    staticClass: "next-button",
    class: { "next-button-white": _vm.color === "white" },
    attrs: {
      icon: _vm.isRtl ? "chevronLeft" : "chevronRight",
      ariaLabel: _vm.$tr("goToNextPage"),
      color: _vm.color,
    },
    on: {
      click: function ($event) {
        return _vm.$emit("goToNextPage")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }