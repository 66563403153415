var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "toc-list-item",
      class: { "toc-list-item-top-level": _vm.depth === 0 },
    },
    [
      _c("KButton", {
        staticClass: "toc-list-item-button",
        class: { "toc-list-item-button-current": _vm.isCurrentSection },
        attrs: {
          id: _vm.sectionId,
          text: _vm.section.label.trim() || _vm.section.href,
          appearance: "basic-link",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("tocNavigation", _vm.section)
          },
        },
      }),
      _vm.section.subitems && _vm.section.subitems.length > 0
        ? _c(
            "ul",
            { staticClass: "toc-list" },
            _vm._l(_vm.section.subitems, function (subsection, index) {
              return _c("TableOfContentsSection", {
                key: index,
                attrs: {
                  section: subsection,
                  currentSection: _vm.currentSection,
                  depth: _vm.depth + 1,
                  "data-test": "table-of-contents-section",
                },
                on: { tocNavigation: _vm.emitTocNavigation },
              })
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }