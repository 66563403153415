var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SideBar", [
    _c(
      "div",
      { staticClass: "o-f-h" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.$tr("textSize")))]),
        _c(
          "KFixedGrid",
          { attrs: { numCols: "2", gutter: "8" } },
          [
            _c(
              "KFixedGridItem",
              { attrs: { span: "1" } },
              [
                _c(
                  "KButton",
                  {
                    ref: "decreaseFontSizeButton",
                    class: [
                      "settings-button",
                      _vm.$computedClass(_vm.settingsButtonFocus),
                    ],
                    attrs: { disabled: _vm.decreaseFontSizeDisabled },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("decreaseFontSize")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("KIcon", {
                              staticStyle: {
                                top: "0",
                                width: "24px",
                                height: "24px",
                              },
                              attrs: { icon: "minus" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("div", { staticClass: "truncate" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$tr("decrease")) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "KFixedGridItem",
              { attrs: { span: "1" } },
              [
                _c(
                  "KButton",
                  {
                    ref: "increaseFontSizeButton",
                    class: [
                      "settings-button",
                      _vm.$computedClass(_vm.settingsButtonFocus),
                    ],
                    attrs: { disabled: _vm.increaseFontSizeDisabled },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("increaseFontSize")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function () {
                          return [
                            _c("KIcon", {
                              staticStyle: {
                                top: "0",
                                width: "24px",
                                height: "24px",
                              },
                              attrs: { icon: "plus" },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("div", { staticClass: "truncate" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$tr("increase")) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "o-f-h" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.$tr("theme")))]),
        _c(
          "KFixedGrid",
          { attrs: { numCols: "4", gutter: "8" } },
          _vm._l(_vm.themes, function (value, key) {
            return _c(
              "KFixedGridItem",
              { key: key, attrs: { span: "1" } },
              [
                _c(
                  "KButton",
                  {
                    staticClass: "settings-button theme-button",
                    attrs: {
                      "aria-label": _vm.generateThemeAriaLabel(key),
                      appearanceOverrides: _vm.generateStyle(value),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("setTheme", value)
                      },
                    },
                  },
                  [
                    _vm.isCurrentlySelectedTheme(value)
                      ? _c("KIcon", {
                          staticStyle: {
                            top: "0",
                            width: "24px",
                            height: "24px",
                          },
                          style: { fill: value.textColor },
                          attrs: { icon: "check" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }