var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SideBar", [
    _c("nav", [
      _c(
        "ul",
        { ref: "tocList", staticClass: "toc-list" },
        [
          _vm._l(_vm.toc, function (section, index) {
            return [
              _c("TableOfContentsSection", {
                key: "toc-section-" + index,
                attrs: {
                  section: section,
                  depth: 0,
                  currentSection: _vm.currentSection,
                },
                on: { tocNavigation: _vm.emitTocNavigation },
              }),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }