var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreFullscreen",
    {
      ref: "epubRenderer",
      staticClass: "epub-renderer",
      class: { small: _vm.windowIsSmall, scrolled: _vm.scrolled },
      style: _vm.epubRendererStyle,
      on: {
        changeFullscreen: function ($event) {
          _vm.isInFullscreen = $event
        },
      },
    },
    [
      _vm.errorLoading
        ? _c("LoadingError", { attrs: { loaded: _vm.loaded } })
        : !_vm.loaded
        ? _c("LoadingScreen")
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "epub-renderer-content",
          style: { "border-color": _vm.$themeTokens.fineLine },
          attrs: { dir: _vm.contentDirection },
          on: {
            mousedown: function ($event) {
              $event.stopPropagation()
              return _vm.handleMouseDown.apply(null, arguments)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeSideBar.apply(null, arguments)
            },
          },
        },
        [
          _c("TopBar", {
            ref: "topBar",
            staticClass: "top-bar-component",
            attrs: {
              isInFullscreen: _vm.isInFullscreen,
              hideSearchButton: _vm.searchSideBarIsOpen,
            },
            on: {
              tableOfContentsButtonClicked: _vm.handleTocToggle,
              settingsButtonClicked: _vm.handleSettingToggle,
              searchButtonClicked: _vm.handleSearchToggle,
              fullscreenButtonClicked: function ($event) {
                return _vm.$refs.epubRenderer.toggleFullscreen()
              },
            },
          }),
          _c(
            "FocusLock",
            { attrs: { disabled: !_vm.tocSideBarIsOpen, returnFocus: true } },
            [
              _vm.tocSideBarIsOpen
                ? _c("TocButton", {
                    staticClass: "toc-button",
                    on: { click: _vm.handleTocToggle },
                  })
                : _vm._e(),
              _c("TableOfContentsSideBar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tocSideBarIsOpen,
                    expression: "tocSideBarIsOpen",
                  },
                ],
                ref: "tocSideBar",
                staticClass: "side-bar side-bar-left",
                attrs: { toc: _vm.toc, currentSection: _vm.currentSection },
                on: { tocNavigation: _vm.handleTocNavigation },
              }),
            ],
            1
          ),
          _c(
            "FocusLock",
            {
              attrs: {
                disabled: !_vm.settingsSideBarIsOpen,
                returnFocus: false,
              },
            },
            [
              _vm.settingsSideBarIsOpen
                ? _c("SettingsButton", {
                    staticClass: "settings-button",
                    on: { click: _vm.handleSettingToggle },
                  })
                : _vm._e(),
              _c("SettingsSideBar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.settingsSideBarIsOpen,
                    expression: "settingsSideBarIsOpen",
                  },
                ],
                ref: "settingsSideBar",
                staticClass: "side-bar side-bar-right",
                attrs: {
                  theme: _vm.theme,
                  decreaseFontSizeDisabled: _vm.decreaseFontSizeDisabled,
                  increaseFontSizeDisabled: _vm.increaseFontSizeDisabled,
                },
                on: {
                  decreaseFontSize: function ($event) {
                    return _vm.handleChangeFontSize(-1)
                  },
                  increaseFontSize: function ($event) {
                    return _vm.handleChangeFontSize(+1)
                  },
                  setTheme: _vm.setTheme,
                },
              }),
            ],
            1
          ),
          _c(
            "FocusLock",
            {
              attrs: { disabled: !_vm.searchSideBarIsOpen, returnFocus: false },
            },
            [
              _vm.searchSideBarIsOpen
                ? _c("SearchButton", {
                    staticClass: "search-button",
                    on: { click: _vm.handleSearchToggle },
                  })
                : _vm._e(),
              _c("SearchSideBar", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchSideBarIsOpen,
                    expression: "searchSideBarIsOpen",
                  },
                ],
                ref: "searchSideBar",
                staticClass: "side-bar side-bar-right",
                attrs: { book: _vm.book },
                on: {
                  newSearchQuery: _vm.handleNewSearchQuery,
                  navigateToSearchResult: _vm.handleNavigateToSearchResult,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "navigation-and-epubjs",
              style: { backgroundColor: _vm.backgroundColor },
            },
            [
              _c(
                "div",
                { staticClass: "column epubjs-navigation" },
                [
                  _vm.contentIsRtl
                    ? _c("NextButton", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isAtEnd,
                            expression: "!isAtEnd",
                          },
                        ],
                        style: { backgroundColor: _vm.backgroundColor },
                        attrs: {
                          disabled: !_vm.locationsAreReady,
                          color: _vm.navigationButtonColor,
                          isRtl: _vm.contentIsRtl,
                        },
                        on: { goToNextPage: _vm.goToNextPage },
                      })
                    : _c("PreviousButton", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isAtStart,
                            expression: "!isAtStart",
                          },
                        ],
                        style: { backgroundColor: _vm.backgroundColor },
                        attrs: {
                          disabled: !_vm.locationsAreReady,
                          color: _vm.navigationButtonColor,
                          isRtl: _vm.contentIsRtl,
                        },
                        on: { goToPreviousPage: _vm.goToPreviousPage },
                      }),
                ],
                1
              ),
              _c("div", {
                ref: "epubjsContainer",
                staticClass: "column epubjs-parent",
                style: { backgroundColor: _vm.backgroundColor },
              }),
              _c(
                "div",
                { staticClass: "column epubjs-navigation" },
                [
                  _vm.contentIsRtl
                    ? _c("PreviousButton", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isAtStart,
                            expression: "!isAtStart",
                          },
                        ],
                        style: { backgroundColor: _vm.backgroundColor },
                        attrs: {
                          disabled: !_vm.locationsAreReady,
                          color: _vm.navigationButtonColor,
                          isRtl: _vm.contentIsRtl,
                        },
                        on: { goToPreviousPage: _vm.goToPreviousPage },
                      })
                    : _c("NextButton", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isAtEnd,
                            expression: "!isAtEnd",
                          },
                        ],
                        style: { backgroundColor: _vm.backgroundColor },
                        attrs: {
                          disabled: !_vm.locationsAreReady,
                          color: _vm.navigationButtonColor,
                          isRtl: _vm.contentIsRtl,
                        },
                        on: { goToNextPage: _vm.goToNextPage },
                      }),
                ],
                1
              ),
            ]
          ),
          _c("BottomBar", {
            staticClass: "bottom-bar",
            attrs: {
              locationsAreReady: _vm.locationsAreReady,
              heading: _vm.bottomBarHeading,
              sliderValue: _vm.sliderValue,
              sliderStep: _vm.sliderStep,
            },
            on: { sliderChanged: _vm.handleSliderChanged },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }