var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SideBar",
    [
      _c(
        "form",
        {
          staticClass: "d-t",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitSearch.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-tr" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.searchQuery,
                    expression: "searchQuery",
                    modifiers: { trim: true },
                  },
                ],
                ref: "searchInput",
                staticClass: "d-tc search-input",
                attrs: {
                  type: "search",
                  "aria-label": _vm.$tr("enterSearchQuery"),
                },
                domProps: { value: _vm.searchQuery },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchQuery = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
              _c("KIconButton", {
                staticClass: "d-tc",
                staticStyle: { position: "relative", top: "4px", left: "8px" },
                attrs: {
                  icon: "search",
                  buttonType: "submit",
                  ariaLabel: _vm.$tr("submitSearchQuery"),
                  size: "small",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("transition", { attrs: { mode: "out-in" } }, [
        !_vm.searchHasBeenMade
          ? _c("p", { key: "no-search", style: _vm.paragraphStyle }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$tr("searchThroughBook")) + "\n    "
              ),
            ])
          : _vm.searchIsLoading
          ? _c(
              "div",
              { key: "loading-true" },
              [
                _c("p", { style: _vm.paragraphStyle }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tr("loadingResults")) +
                      "\n      "
                  ),
                ]),
                _c("KCircularLoader", { attrs: { delay: false } }),
              ],
              1
            )
          : _vm.searchResults.length === 0
          ? _c("p", { key: "results-false", style: _vm.paragraphStyle }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$tr("noSearchResults")) + "\n    "
              ),
            ])
          : _vm.searchResults.length > 0
          ? _c("p", { key: "results-true", style: _vm.paragraphStyle }, [
              _vm._v("\n      " + _vm._s(_vm.numberOfSearchResults) + "\n    "),
            ])
          : _vm._e(),
      ]),
      _vm.searchHasBeenMade &&
      !_vm.searchIsLoading &&
      _vm.searchResults.length > 0
        ? _c(
            "ol",
            { ref: "searchResultsList", staticClass: "search-results-list" },
            _vm._l(_vm.searchResults, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass: "search-results-list-item",
                  style: {
                    borderTop: "solid 1px " + _vm.$themeTokens.fineLine,
                  },
                },
                [
                  _c(
                    "KButton",
                    {
                      staticClass: "search-results-list-item-button",
                      attrs: { appearance: "basic-link" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("navigateToSearchResult", item)
                        },
                      },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(item.before))]),
                      _c("span", { style: _vm.markColor }, [
                        _vm._v(_vm._s(item.match)),
                      ]),
                      _c("span", [_vm._v(_vm._s(item.after))]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }