var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "top-bar",
      style: { backgroundColor: _vm.$themePalette.grey.v_200 },
    },
    [
      _c(
        "KGrid",
        { style: { marginTop: "2px", marginLeft: "3px", marginRight: "3px" } },
        [
          _c(
            "KGridItem",
            {
              attrs: {
                layout4: { span: 1 },
                layout8: { span: 2 },
                layout12: { span: 3 },
              },
            },
            [
              _c("TocButton", {
                ref: "tocButton",
                on: {
                  click: function ($event) {
                    return _vm.$emit("tableOfContentsButtonClicked")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "KGridItem",
            {
              attrs: {
                layout: { alignment: "center" },
                layout4: { span: 1 },
                layout8: { span: 4 },
                layout12: { span: 6 },
              },
            },
            [
              _vm.title
                ? _c("h2", { staticClass: "top-bar-title" }, [
                    _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
                  ])
                : _vm._e(),
            ]
          ),
          _c(
            "KGridItem",
            {
              attrs: {
                layout: { alignment: "right" },
                layout4: { span: 2 },
                layout8: { span: 2 },
                layout12: { span: 3 },
              },
            },
            [
              _c("SettingsButton", {
                ref: "settingsButton",
                on: {
                  click: function ($event) {
                    return _vm.$emit("settingsButtonClicked")
                  },
                },
              }),
              _c("SearchButton", {
                ref: "searchButton",
                class: { invisible: _vm.$attrs.hideSearchButton },
                on: {
                  click: function ($event) {
                    return _vm.$emit("searchButtonClicked")
                  },
                },
              }),
              _c("KIconButton", {
                ref: "fullscreenButton",
                attrs: {
                  icon: _vm.isInFullscreen ? "fullscreen_exit" : "fullscreen",
                  ariaLabel: _vm.$tr("toggleFullscreen"),
                  size: "small",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("fullscreenButtonClicked")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }