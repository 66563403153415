var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "epub-renderer-error",
      class: { loaded: _vm.loaded },
      style: { background: _vm.$themeTokens.surface },
    },
    [
      _c("UiAlert", { attrs: { type: "error", dismissible: false } }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("couldNotLoadThisBook")) + "\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }